import { Menu, X, } from 'lucide-react';
import { useState } from 'react';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { name: 'Support Server', href: 'https://discord.gg/EwetguKHrQ'},
    { name: 'Status', href: 'https://status.simplica.dev/' },
    // { name: 'Dokumentation', href: '/documentation' },
    { name: 'Einladen', href: 'https://simplica.dev/invite', highlight: true },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 bg-[#2E2E2E] border-b border-gray-700 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <a href="/"><img src="https://cdn.simplica.dev/ico/icon_rounded.ico" alt="Simplica Logo" className="h-8 w-8" /></a>
            <a href="/"><span className="ml-2 text-white text-xl font-semibold">Simplica Bot</span></a>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:block">
            <div className="flex items-center space-x-4">
              {navItems.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  // target="_blank"
                  rel="noopener noreferrer"
                  className={`px-3 py-2 rounded-md text-sm font-medium transition-colors
                    ${
                      item.highlight
                        ? 'bg-[#6C8AE4] text-white hover:bg-[#5C4A8D]'
                        : 'text-gray-300 hover:text-[#6C8AE4]'
                    }`}
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-400 hover:text-white"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  item.highlight
                    ? 'bg-[#6C8AE4] text-white'
                    : 'text-gray-300 hover:text-[#6C8AE4]'
                }`}
              >
                {item.name}
              </a>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}