import { useEffect } from "react";

export default function Privacy() {

  useEffect(() => {
    // Setze den Titel auf "Impressum+"
    document.title = "Datenschutz";
  }, []);

  return (
    <div className="min-h-screen pt-20 pb-12 px-4">
      <div className="max-w-3xl mx-auto bg-[#3A3A3A] rounded-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Datenschutzerklärung</h1>

        <div className="space-y-6 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold text-white mb-2">1. Datenschutzrichtlinie</h2>
            <p>
              Wir sind verpflichtet, Ihre Privatsphäre zu schützen. Diese Richtlinie erklärt, wie wir Ihre persönlichen Daten sammeln, verwenden und schützen, wenn Sie Simplica nutzen.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">2. Datensammlung</h2>
            <p>
              Wir können und speichern bestimmte Informationen, wenn Sie Simplica nutzen, einschließlich:
            </p>
            <ul>
              <li>Discord-Benutzer-ID</li>
              <li>Server-ID</li>
              <li>Nachrichteninhalte</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">3. Datennutzung</h2>
            <p>
              Wir verwenden die gesammelten Daten, um Simplica zu bereitstellen und zu warten, zu analysieren, wie es genutzt wird, und seine Funktionalität zu verbessern.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">4. Datensicherheit</h2>
            <p>
              Wir ergreifen geeignete Maßnahmen, um die gesammelten Daten vor unbefugtem Zugriff, Änderung, Offenlegung oder Zerstörung zu schützen.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">5. Zugang und Löschung</h2>
            <p>
              Sie haben das Recht, den Zugriff auf Ihre persönlichen Daten zu beantragen, die wir über Sie halten, und diese zu korrigieren oder zu löschen.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">6. Nutzung von Nachrichteninhalten</h2>
            <p>
              Wir speichern keine Ihrer Nachrichteninhalte dauerhaft. Die Nachrichteninhalt wird ausschließlich zur Verarbeitung der Funktionalität unserer Systeme verwendet und kann vorübergehend für bis zu 29 Tage gespeichert werden. Wir nutzen Nachrichteninhalte nicht zur Verbesserung unserer Dienste oder zu anderen Zwecken.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">7. Änderungen dieser Richtlinie</h2>
            <p>
              Wir behalten uns das Recht vor, unsere Datenschutzrichtlinie jederzeit zu aktualisieren oder zu ändern. Jegliche Änderungen gelten mit sofortiger Wirkung ab dem Zeitpunkt des Hinzufügens der aktualisierten Richtlinie auf dieser Seite.
            </p>
          </section>

          <footer className="text-center text-white">
            &copy; 2024 Simplica. Alle Rechte vorbehalten.
          </footer>
        </div>
      </div>
    </div>
  );
}