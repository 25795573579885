import { useEffect } from "react";

export default function Impressum() {

  useEffect(() => {
    // Setze den Titel auf "Impressum+"
    document.title = "Impressum";
  }, []);


  return (
    <div className="min-h-screen pt-20 pb-12 px-4">
      <div className="max-w-3xl mx-auto bg-[#3A3A3A] rounded-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Impressum</h1>

        <div className="space-y-6 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Angaben gemäß § 5 TMG</h2>
            <p>Fraab UG (haftungsbeschränkt)</p>
            <p>Kurt-Eisner Str. 17</p>
            <p>04275 Leipzig</p>
            <p>Deutschland</p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">Kontakt</h2>
            <p>Telefon: 0341 – 225 38 50</p>
            <p>E-Mail: kontakt@nexiva.de</p>
          </section>

          {/* <section>
            <h2 className="text-xl font-semibold text-white mb-2">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
            <p>Max Mustermann</p>
            <p>Musterstraße 123</p>
            <p>12345 Musterstadt</p>
          </section> */}
        </div>
      </div>
    </div>
  );
}