import { useEffect } from "react";

export default function Terms() {

  useEffect(() => {
    // Setze den Titel auf "Impressum+"
    document.title = "Terms of Service";
  }, []);

  return (
    <div className="min-h-screen pt-20 pb-12 px-4">
      <div className="max-w-3xl mx-auto bg-[#3A3A3A] rounded-xl p-8">
        <h1 className="text-3xl font-bold text-white mb-6">Terms of Service</h1>

        <div className="space-y-6 text-gray-300">
          <section>
            <h2 className="text-xl font-semibold text-white mb-2">1. Nutzungsbedingungen</h2>
            <p>
              Mit der Nutzung von Simplica stimmen Sie diesen Nutzungsbedingungen zu.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">2. Verfügbarkeit und Änderungen</h2>
            <p>
              Sie müssen sich an alle Nutzungsbedingungen und Community-Leitlinien von Discord halten.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">3. Haftungsausschluss</h2>
            <p>
              Simplica wird ohne jegliche Gewährleistung oder Garantie bereitgestellt.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">4. Unbefugte Nutzung</h2>
            <p>
              Sie dürfen Simplica nicht für illegale oder unautorisierte Zwecke verwenden.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">5. Änderungen und Beendigung</h2>
            <p>
              Wir behalten uns das Recht vor, Simplica jederzeit für jeden Grund zu ändern oder einzustellen, ohne Vorankündigung, zu jedem Zeitpunkt.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-semibold text-white mb-2">6. Zugriffsbeschränkung</h2>
            <p>
              Wir behalten uns das Recht vor, Ihren Zugriff auf Simplica zu sperren oder zu beenden, falls Sie diese Nutzungsbedingungen verletzen.
            </p>
          </section>

          <footer className="text-center text-white">
            &copy; 2024 Simplica. Alle Rechte vorbehalten.
          </footer>
        </div>


      </div>
    </div>
  );
}