// import { useEffect, useRef } from 'react';
// import { Shield, MessageSquare, Zap, Users, Star, Music } from 'lucide-react';
import { Shield, MessageSquare, Zap, Users, Star } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: 'Erweiterte Moderation',
    description: 'Schütze deine Community mit intelligenten Moderationstools und automatischer Spam-Erkennung.'
  },
  {
    icon: MessageSquare,
    title: 'Custom Commands',
    description: 'Erstelle personalisierte Befehle und automatisierte Antworten für deinen Server.'
  },
  {
    icon: Zap,
    title: 'Schnelle Performance',
    description: 'Genieße blitzschnelle Reaktionszeiten und zuverlässige Verfügbarkeit.'
  },
  {
    icon: Users,
    title: 'Community Management',
    description: 'Verwalte deine Mitglieder effizient mit Rollen, Leveln und Willkommensnachrichten.'
  },
  {
    icon: Star,
    title: 'Premium Features',
    description: 'Zugang zu exklusiven Funktionen und priorisierten Support für deine Community.'
  },
  // {
  //   icon: Music,
  //   title: 'Musik System',
  //   description: 'Genieße hochwertige Musik mit Playlists, Warteschlangen und DJ-Rollen für deine Community.'
  // }
];

export default function Features() {
  return (
    <section className="py-24 bg-[#2E2E2E]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-white mb-4">
            Unsere Features
          </h2>
          <p className="text-gray-400">
            Entdecke die vielfältigen Möglichkeiten von Simplica
          </p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {features.map((feature, index) => (
            <div
              key={index}
              className="feature-card opacity-0 translate-y-8 transition-all duration-700 ease-out"
              style={{ transitionDelay: `${index * 200}ms` }}
            >
              <div className="bg-[#3A3A3A] rounded-xl p-6 h-full transform hover:scale-105 transition-transform">
                <feature.icon className="w-12 h-12 text-[#6C8AE4] mb-4" />
                <h3 className="text-xl font-semibold text-white mb-2">
                  {feature.title}
                </h3>
                <p className="text-gray-400">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}