import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom'; // Für React Router v6
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import Impressum from './pages/Impressum';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import './App.css';
import React from 'react';

function HomePage() {
  return (
    <>
      <Hero />
      <Features />
    </>
  );
}

// Die Komponente für die externe Weiterleitung
function ExternalRedirect({ url }) {
  React.useEffect(() => {
    window.location.href = url; // Führt eine Weiterleitung zur externen URL aus
  }, [url]);
  return null; // Keine visuelle Ausgabe notwendig
}

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-[#2E2E2E]">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
          <Route
            path="/invite"
            element={<ExternalRedirect url="https://discord.com/oauth2/authorize?client_id=1138127842333442169&scope=bot&permissions=8" />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
