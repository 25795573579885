// import { ArrowRight } from 'lucide-react';

export default function Hero() {
  return (
    <div className="relative min-h-screen flex items-center justify-center px-4">
      {/* Background decoration */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[800px] h-[800px] bg-[#4A4A4A]/10 rounded-full blur-3xl" />
      </div>

      <div className="relative max-w-3xl mx-auto text-center">
        <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold text-white mb-6">
          Optimiere deinen Server mit{' '}
          <span className="bg-gradient-to-r from-pink-500 via-blue-500 to-purple-500 text-transparent bg-clip-text">
            Simplica
          </span>
        </h1>
        
        <p className="text-xl text-gray-300 mb-12">
          Alles, was du brauchst, um deine Community zu begeistern.
        </p>

        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          {/* <a
            href="/dashboard"
            className="group inline-flex items-center px-6 py-3 text-lg font-medium text-white bg-[#6C8AE4] rounded-lg hover:bg-[#5C4A8D] transition-colors"
          >
            Dashboard öffnen
            <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
          </a> */}
          
          <a
            href="https://simplica.dev/invite"
            className="inline-flex items-center px-6 py-3 text-lg font-medium text-[#6C8AE4] border-2 border-[#6C8AE4] rounded-lg hover:bg-[#6C8AE4] hover:text-white transition-colors"
          >
            Einladen
          </a>
        </div>
      </div>
    </div>
  );
}